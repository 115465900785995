import ExternalLink from 'components/ExternalLink/ExternalLink'
import { getExplorerUrl } from 'config/chains'
import { IS_VERBOSE } from 'config/development'
import { BigNumber, Contract, Wallet } from 'ethers'
import { PendingTransaction } from 'hooks/usePaymaster'
import React, { ReactNode } from 'react'
import { helperToast } from '../helperToast'
import { TxError, getErrorMessage } from './transactionErrors'
import { getBestNonce, getGasLimit, setGasPrice } from './utils'

export async function callContract(
  chainId: number,
  contract: Contract,
  method: string,
  params: any,
  opts: {
    value?: BigNumber | number
    gasLimit?: BigNumber | number
    detailsMsg?: ReactNode
    sentMsg?: string
    successMsg?: string
    hideSentMsg?: boolean
    hideSuccessMsg?: boolean
    showPreliminaryMsg?: boolean
    failMsg?: string
    customSigners?: Wallet[]
    pendingTxns?: PendingTransaction[]
    setPendingTxns?: (txns: PendingTransaction[]) => void
    nonce?: number
  },
) {
  try {
    const wallet = contract.runner as Wallet

    if (
      !Array.isArray(params) &&
      typeof params === 'object' &&
      opts === undefined
    ) {
      opts = params
      params = []
    }

    if (!opts) {
      opts = {}
    }

    const txnOpts: any = {}

    if (opts.value) {
      txnOpts.value = opts.value
    }

    txnOpts.nonce = opts.customSigners
      ? await getBestNonce([wallet, ...opts.customSigners])
      : opts.nonce

    if (opts.showPreliminaryMsg && !opts.hideSentMsg) {
      showCallContractToast({
        chainId,
        sentMsg: opts.sentMsg || `Transaction sent.`,
        detailsMsg: opts.detailsMsg || '',
      })
    }

    const customSignerContracts =
      opts.customSigners?.map((signer) => contract.connect(signer)) || []

    const txnCalls = [contract, ...customSignerContracts].map(
      async (cntrct) => {
        const txnInstance = { ...txnOpts }

        txnInstance.gasLimit = opts.gasLimit
          ? opts.gasLimit
          : await getGasLimit(cntrct, method, params, opts.value)

        if (!cntrct?.provider) {
          throw new Error('No provider found on contract.')
        }

        await setGasPrice(txnInstance, cntrct.provider, chainId)

        return cntrct[method](...params, txnInstance)
      },
    )

    const res = await Promise.any(txnCalls).catch(({ errors }) => {
      if (errors.length > 1) {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.error('All transactions failed', ...errors)
      }

      throw errors[0]
    })

    if (!opts.hideSentMsg) {
      showCallContractToast({
        chainId,
        sentMsg: opts.sentMsg || `Transaction sent.`,
        detailsMsg: opts.detailsMsg || '',
        hash: res.hash,
      })
    }

    if (opts.setPendingTxns) {
      const message = opts.hideSuccessMsg
        ? undefined
        : opts.successMsg || `Transaction completed!`
      const pendingTxn = {
        hash: res.hash,
        message,
        messageDetails: opts.detailsMsg,
      }

      if (opts.pendingTxns) {
        opts.setPendingTxns([...opts.pendingTxns, pendingTxn])
      }
    }

    return res
  } catch (e) {
    const { failMsg, autoCloseToast } = getErrorMessage(
      chainId,
      e as TxError,
      opts?.failMsg,
    )

    helperToast.error(failMsg, { autoClose: autoCloseToast })
    throw e
  }
}

export function showCallContractToast({
  chainId,
  hash,
  sentMsg,
  detailsMsg,
  toastId,
}: {
  chainId: number
  hash?: string
  sentMsg: string
  detailsMsg?: React.ReactNode
  toastId?: string
}) {
  helperToast.success(
    <div>
      {sentMsg || `Transaction sent.`}{' '}
      {hash && (
        <ExternalLink href={getExplorerUrl(chainId) + 'tx/' + hash}>
          <>View status.</>
        </ExternalLink>
      )}
      <br />
      {detailsMsg && <br />}
      {detailsMsg}
    </div>,
    {
      toastId,
    },
  )
}
